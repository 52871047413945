<template>
  <div class="form-line" :class="{'-top': value || inputFocus || autofill || type === 'date', '-icon': type === 'password', '-tooltip': tooltip, '-error': validations.find( o => o.condition )}">
    <label :for="id">{{ placeholder }}</label>
    <!-- Prevent keyspace on cardnumber -->
    <input
      v-if="keyspace"
      :id="id"
      :type="type === 'password' ? passType : type"
      :name="name"
      :autocomplete="autocomplete"
      :value="value"
      :autofocus="autofocus"
      :ref="name"
      @blur="blur()"
      @keydown.space.prevent
      @focus="inputFocus = true"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('keyup.enter', $event.target.value)"
      @keyup="$emit('keyup', $event)"
    >
    <input
      v-else
      :id="id"
      :type="type === 'password' ? passType : type"
      :name="name"
      :autocomplete="autocomplete"
      :value="value"
      :autofocus="autofocus"
      :ref="name"
      :required="required ? true : false"
      :aria-required="required ? true : false"
      :aria-invalid="validations.find( o => o.condition ) ? true : false"
      :aria-describedby="validations.find( o => o.condition ) ? 'error-' + id : false"
      @blur="blur()"
      @focus="inputFocus = true"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('keyup.enter', $event.target.value)"
      @keyup="$emit('keyup', $event)"
    >
    <button
      v-if="type === 'password'"
      type="button"
      @click="togglePassType()"
      :aria-label="$t('Toggle password visibility')"
      :title="$t('Toggle password visibility')" >
      <i :class="icon"></i>
    </button>
    <button
      v-if="tooltip"
      type="button"
      class="info"
      @click="$emit('openTooltip', true)"
      :aria-expanded="tooltipState ? 'true' : 'false'"
      :aria-controls="'modal-' + id"
      :aria-label="placeholder + ' ' + $t('(opens tooltip)')">
      <i class="icon-info"></i>
    </button>
    <ValidationMessages v-if="validations" :validations="validations" :id="id" :wrapper-style="errorWrapperStyle"/>
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'

export default {
  name: 'BaseInput',
  components: {
    ValidationMessages
  },
  data () {
    return {
      inputFocus: false,
      passType: 'password',
      iconActive: false,
      icon: 'icon-view-on',
      autofill: false
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: null
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    },
    keyspace: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    },
    tooltipState: {
      type: Boolean,
      required: false,
      default: false
    },
    errorWrapperStyle: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  methods: {
    togglePassType () {
      if (this.passType === 'password') {
        this.passType = 'text'
        this.icon = 'icon-view-off'
      } else {
        this.passType = 'password'
        this.icon = 'icon-view-on'
      }
    },
    // setFocus sets focus on a field which has a value of 'ref' tag equal to fieldName
    setFocus (fieldName) {
      if (this.name === fieldName) {
        this.$refs[this.name].focus()
      }
    },
    blur () {
      this.inputFocus = false
      this.$emit('blur')
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs[this.name].focus()
    }

    try {
      if (this.$el.querySelector('input:-webkit-autofill')) {
        this.autofill = true
      }
    } catch (e) {}
  }
}
</script>
